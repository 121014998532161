import { RestrictionSelectorType } from './restriction-selector-type';

export class RestrictionItem {
    id: number;
    name: string;
    restrcitionSelectorType: RestrictionSelectorType = RestrictionSelectorType.Dropdown;

    constructor(params: RestrictionItem) {
        Object.assign(this, params);
    }
}

export interface ISelectedRestrictionItem {
    id: number;
    text: string;
}

export interface ISelectedItemsChangedEvent {
    restirctionItem: RestrictionItem;
    selectedItems: Array<ISelectedRestrictionItem>;
    isInclusive: boolean;
}

export interface IRestrictionSummaryEvent {
    id: number;
    code: string;
}
