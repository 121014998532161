<div class="row restriction-header form-horizontal" *ngIf="selectedRestrictionList?.length">
  <div class="form-group">
    <div [ngClass]="cssGridClassLabel" class="text-right">
      <label class="noreq-lab">Type</label>
    </div>
    <div [ngClass]="cssGridClassValue">
      <label>{{ showInclusiveExclusive ? 'Parameters' : 'Restrictions' }}</label>
    </div>
  </div>
</div>
<div class="row form-horizontal" *ngFor="let item of selectedRestrictionList">
  <div class="restriction-type form-group">
    <div [ngClass]="cssGridClassLabel" class="text-right">
      <label class="restriction-type-label noreq-lab">
        <a *ngIf="showLabelAsHyperlink" (click)="clickRestrictionType(item)">{{item.RestrictionTypeName}}</a>
        <span *ngIf="!showLabelAsHyperlink">{{item.RestrictionTypeName}}</span>
      </label>
    </div>
    <div [ngClass]="cssGridClassValue">
      <ng-container *ngIf="showInclusiveExclusive">
        <ng-container [ngSwitch]="item.IsInclusive">
          <strong *ngSwitchCase="true">Includes: </strong>
          <strong *ngSwitchCase="false">Excludes: </strong>
          <strong *ngSwitchDefault>Includes: </strong>
        </ng-container>
      </ng-container>

      <span *ngFor="let restriction of item.SelectedRestrictions; let last = last">
        {{restriction.Name}}{{ !last ? ', ' : '' }}
      </span>
    </div>
  </div>
</div>