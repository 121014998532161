import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RestrictionItem, RestrictionSelectorType, ISelectedItemsChangedEvent } from '../share';
import { RestrictionSelectorComponent } from '../restriction-selector/restriction-selector.component';
import { PhxModalComponent } from 'src/app/common/components/phx-modal/phx-modal.component';
import { PhxButton } from 'src/app/common/model';

@Component({
  selector: 'app-restriction-dropdown',
  templateUrl: './restriction-dropdown.component.html',
  styleUrls: ['./restriction-dropdown.component.less']
})
export class RestrictionDropdownComponent implements OnInit {

  @Input() editable = true;
  @Input() labelText = 'Restrictions';
  @Input() dropdownText = 'Add/Edit Restrictions';
  @Input() entityName = 'item';
  @Input() dropdowmitems: Array<RestrictionItem> = [];

  @Input() items: Array<{ id: number, text: string, type: RestrictionSelectorType }>;
  @Input() selectedItems: Array<number>;
  @Input() isInclusive = true;
  @Input() showIsInclusive = false;

  @Output() restrictionItemClick: EventEmitter<RestrictionItem> = new EventEmitter<RestrictionItem>();
  @Output() selectedItemsChanged = new EventEmitter<ISelectedItemsChangedEvent>();

  @ViewChild('itemModal', {static: true}) itemModal: PhxModalComponent;
  @ViewChild('restrictionSelector') restrictionSelector: RestrictionSelectorComponent;

  selectedRestriction: RestrictionItem;

  itemButtons: PhxButton[] = [
    {
      icon: null,
      tooltip: 'Ok',
      btnType: 'primary',
      action: () => this.ok()
    },
    {
      icon: null,
      tooltip: 'Cancel',
      btnType: 'default',
      action: () => this.cancel()
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

  itemClick(item: RestrictionItem) {
    this.restrictionItemClick.emit(item);

    if (this.items === undefined) {
      this.items = [];
    }

    this.selectedItems = [];
    if (!this.itemModal.isVisible) {
      this.showModal(item);
    }
  }

  close() {
    this.itemModal.hide();
  }

  cancel() {
    this.itemModal.hide();
  }

  ok() {
    this.itemModal.hide();
    this.selectedItemsChanged.emit({
      restirctionItem: this.selectedRestriction,
      selectedItems: this.restrictionSelector.model.selectedItemsWithText,
      isInclusive: this.restrictionSelector.model.isInclusive
    });
  }

  showModal(item: RestrictionItem) {
    this.selectedRestriction = item;
    this.itemModal.show();
  }

  public openRestrictionSelector(id: number) {
    const item = this.dropdowmitems.find((i) => i.id === id);
    if (item) {
      this.itemClick(item);
    }
  }
}
