import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { RestrictionSelectorType, ISelectedRestrictionItem } from '../share/index';
import { PhxSelectBoxComponent } from '../../common/components/phx-select-box/phx-select-box.component';

export interface ICheckboxRestrictionItem extends ISelectedRestrictionItem {
  checked: boolean;
}

@Component({
  selector: 'app-restriction-selector',
  templateUrl: './restriction-selector.component.html',
  styleUrls: ['./restriction-selector.component.less']
})
export class RestrictionSelectorComponent implements OnChanges {

  @Input() restrictionSelectorType: RestrictionSelectorType = RestrictionSelectorType.Dropdown;
  @Input() labelText = 'Restriction';
  @Input() showIsInclusive: boolean;
  @Input() entityName = 'Item';

  @Input() items: Array<ISelectedRestrictionItem>;
  @Input() selectedItems: Array<number>;
  @Input() isInclusive: boolean;

  @ViewChild('select') select: PhxSelectBoxComponent;

  dropdownItems: ISelectedRestrictionItem[];
  checkboxItems: ICheckboxRestrictionItem[];
  maxCheckboxItems = 10;

  model: {
    isInclusive: boolean;
    selectedItemsWithText: Array<ISelectedRestrictionItem>;
  } = {
    isInclusive: true,
    selectedItemsWithText: []
  };
  restrictionSelectorTypeEnum = RestrictionSelectorType;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items || changes.selectedItems) {
      this.model.isInclusive = this.isInclusive;

      if (this.items?.length > this.maxCheckboxItems && this.restrictionSelectorType !== RestrictionSelectorType.Dropdown) {
        this.restrictionSelectorType = RestrictionSelectorType.Dropdown;
      }

      if (this.restrictionSelectorType === RestrictionSelectorType.Dropdown) {
        this.generateDropdownItems();
      } else if (this.restrictionSelectorType === RestrictionSelectorType.Checkbox) {
        this.generateCheckboxItems();
      }
    }
  }

  generateDropdownItems() {
    if (this.items == null) {
      return;
    }

    this.dropdownItems = this.spreadAndSortArray(this.items);
    this.model.selectedItemsWithText = [];

    if (this.selectedItems == null) {
      return;
    }

    this.selectedItems.forEach(selectedItemId => {
      this.addItemToSelectedList(this.dropdownItems, selectedItemId);
      this.removeItemFromDropdownItems(selectedItemId);
    });

    this.model.selectedItemsWithText = this.spreadAndSortArray(this.model.selectedItemsWithText);
  }

  removeItemFromDropdownItems(id: number) {
    if (id !== null) {
      this.dropdownItems.splice(this.dropdownItems.findIndex(i => i.id === id), 1);

      // to force angular change detection work on array
      this.dropdownItems = this.spreadAndSortArray(this.dropdownItems);
    }
  }

  addDeletedItemToItemList(id: number) {
    if (id !== null) {
      this.model.selectedItemsWithText.splice(this.model.selectedItemsWithText.findIndex(i => i.id === id), 1);

      const idx = this.items.findIndex(i => i.id === id);
      if (idx !== -1) {
        this.dropdownItems.push(this.items[idx]);
      }

      // to force angular change detection work on array
      this.model.selectedItemsWithText = this.spreadAndSortArray(this.model.selectedItemsWithText);
      this.dropdownItems = this.spreadAndSortArray(this.dropdownItems);
    }
  }

  addItemToSelectedList(source: Array<ISelectedRestrictionItem>, id: number) {
    if (id !== null) {
      const idx = source.findIndex(i => i.id === id);
      if (idx !== -1) {
        this.model.selectedItemsWithText.push({ id, text: source[idx].text });
      }

      this.model.selectedItemsWithText = this.spreadAndSortArray(this.model.selectedItemsWithText);
    }
  }

  spreadAndSortArray(array: Array<ISelectedRestrictionItem>) {
    return [...array.sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    })];
  }

  dropdownChanged(id) {
    if (id) {
      this.addItemToSelectedList(this.dropdownItems, id);
      this.removeItemFromDropdownItems(id);
      this.select.clear();
    }
  }

  generateCheckboxItems() {
    if (this.items == null) {
      return;
    }
    this.items.forEach((i: any) => i.checked = false);
    this.checkboxItems = this.spreadAndSortArray(this.items) as ICheckboxRestrictionItem[];
    this.model.selectedItemsWithText = [];

    if (this.selectedItems == null) {
      return;
    }

    this.selectedItems.forEach(selectedItemId => {
      const idx = this.checkboxItems.findIndex(item => item.id === selectedItemId);
      if (idx != null && idx !== -1) {
        this.checkboxItems[idx].checked = true;
        this.addItemToSelectedList(this.checkboxItems, selectedItemId);
      }
    });

    this.checkboxItems = this.spreadAndSortArray(this.checkboxItems) as ICheckboxRestrictionItem[];
    this.model.selectedItemsWithText = this.spreadAndSortArray(this.model.selectedItemsWithText);
  }

  removeItemFromSelectedItemsWithText(id: number) {
    if (id !== null) {
      this.model.selectedItemsWithText.splice(this.model.selectedItemsWithText.findIndex(i => i.id === id), 1);

      // to force angular change detection work on array
      this.model.selectedItemsWithText = this.spreadAndSortArray(this.model.selectedItemsWithText);
    }
  }

  checkboxChanged(id: number, event) {
    this.checkboxItems.find(c => c.id === id).checked = event.target.checked;
    if (event.target.checked === true) {

      this.addItemToSelectedList(this.checkboxItems, id);
    } else {
      this.removeItemFromSelectedItemsWithText(id);
    }
  }
}
