
<phx-form-control name="restrictions" [labelText]="labelText" [editable]="editable" viewModeText="">
    
  <div class="btn-group" *ngIf="dropdowmitems">

    <button class="btn btn-clouds dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="true">
      {{dropdownText}}
      <span class="caret"></span>
    </button>
    <ul *ngIf="dropdowmitems?.length > 0" class="dropdown-menu bordered" aria-labelledby="dropdownMenu1">
      <li *ngFor="let item of dropdowmitems" (click)="itemClick(item)" class="action-link">
        <a> {{item.name}} </a>
      </li>
    </ul>
  </div>
</phx-form-control>

<app-phx-modal #itemModal title="{{dropdownText}} - {{selectedRestriction?.name}}" [buttons]="itemButtons">
  <div *ngIf="selectedRestriction" class="item-modal">
    <app-restriction-selector #restrictionSelector [restrictionSelectorType]="selectedRestriction.restrcitionSelectorType" [labelText]="selectedRestriction.name"
      [showIsInclusive]="showIsInclusive" [entityName]="entityName"
      [items]="items" [selectedItems]="selectedItems" [isInclusive]="isInclusive">
    </app-restriction-selector>
  </div>
</app-phx-modal>
