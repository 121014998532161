<div *ngIf="showIsInclusive">
  <div class="radio">
    <label>
      <input type="radio" [(ngModel)]="model.isInclusive" [checked]="model.isInclusive" [value]="true">
      <strong>Included:</strong> Select any items to which this {{ entityName | lowercase }} should be applied.
    </label>
  </div>
  <div class="radio">
    <label>
      <input type="radio" [(ngModel)]="model.isInclusive" value="false" [checked]="!model.isInclusive" [value]="false">
      <strong>Excluded:</strong> Select any items to which this {{ entityName | lowercase }} should not be applied.
    </label>
  </div>
</div>

<div [ngSwitch]="restrictionSelectorType">

  <div *ngSwitchCase="restrictionSelectorTypeEnum.Dropdown">
    <div class="form-horizontal">
      <phx-form-control name="restrictions" [labelText]="labelText" [editable]="true" viewModeText="">
        <app-phx-select-box #select [items]="dropdownItems" textField="text" valueField="id"
          (valueChanged)="dropdownChanged($event.value)" [disabled]="dropdownItems.length === 0">
        </app-phx-select-box>
      </phx-form-control>
    </div>
    <hr />

    <table class="table table-striped margin-20-v-t">
      <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let selectedItem of model.selectedItemsWithText">
          <tr>
            <td>{{selectedItem.text}}</td>
            <td>
              <button type="button" class="btn btn-default btn-material btn-borderless pull-right"
                (click)="addDeletedItemToItemList(selectedItem.id)">
                <i class="material-icons">delete</i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div *ngSwitchCase="restrictionSelectorTypeEnum.Checkbox" class="checkbox-container">
    <div *ngFor="let item of checkboxItems" class="row">
      <div class="col-xs-12">

        <label class="checkbox checkbox-label pull-left">
          <input type="checkbox" value="{{item.id}}" [checked]="item.checked === true"
            (change)="checkboxChanged(item.id, $event)">{{item.text}}
        </label>
      </div>
    </div>
  </div>

</div>